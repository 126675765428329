@charset "utf-8";
/* SIR 지운아빠 */
/* 초기화 */
html {
  overflow: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: "Pretendard-Regular", sans-serif;
  font-weight: 400;
  word-break: keep-all;
}
html,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
img {
  margin: 0;
  padding: 0;
  border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul,
li,
dd,
dl,
dt {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cf:after {
  display: block;
  content: "";
  clear: both;
}
.hide {
  display: none;
}

#hd ul,
nav ul,
#ft ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
legend {
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
  overflow: hidden;
}
label,
input,
button,
select,
img {
  vertical-align: middle;
}
input,
button {
  margin: 0;
  padding: 0;
  font-size: 1em;
}
button {
  cursor: pointer;
}

textarea,
select {
  font-family: dotum;
  font-size: 1em;
}
select {
  margin: 0;
}
p {
  margin: 0;
  padding: 0;
}
hr {
  display: none;
}
pre {
  overflow-x: scroll;
  font-size: 1.1em;
}
a:link,
a:visited {
  color: #565656;
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  color: #000;
  text-decoration: none;
  transition: all 0.3s;
}

/* 팝업레이어 */
#hd_pop {
  z-index: 1000;
  position: relative;
  margin: 0 auto;
  width: 970px;
  height: 0;
}
@media screen and (max-width: 767px) {
  #hd_pop {
    z-index: 1000;
    position: relative;
    margin: 0 auto;
    width: 100% !important;
    height: 0;
  }
  #hd_pop img {
    max-width: 100%;
  }
}
#hd_pop h2 {
  position: absolute;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.hd_pops {
  position: absolute; /*border:1px solid #e9e9e9*/
  background: #fff;
  box-shadow: 0 0 3px #333;
}
@media screen and (max-width: 767px) {
  .hd_pops {
    width: 100% !important;
    position: absolute;
    top: 0px !important;
    left: 0 !important;
  }
  .hd_pops img {
    width: 100% !important;
    height: 100% !important;
  }
}
.hd_pops_con {
}
@media screen and (max-width: 767px) {
  .hd_pops_con {
    width: 100% !important;
    height: 100% !important;
  }
}
.hd_pops_footer {
  padding: 10px 0;
  background: #000;
  color: #fff;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .hd_pops_footer {
    width: 100% !important;
  }
}
.hd_pops_footer button {
  margin-right: 5px;
  padding: 5px 10px;
  border: 0;
  background: #393939;
  color: #fff;
  font-size: 0.9em;
}

/* 텍스트 크기 조절 */
#text_size {
  float: left;
  margin: 0 0 0 10px;
  letter-spacing: -3px;
}
#text_size button {
  margin: 0;
  padding: 1px 2px;
  border: 1px solid #c3c6ca;
  background: transparent;
  vertical-align: middle;
  cursor: pointer;
}
.ts_up {
  font-size: 1.167em !important;
}
.ts_up2 {
  font-size: 1.3em !important;
}

/* 게시물 선택복사 선택이동 */
#copymove {
}
.copymove_current {
  float: right;
  color: #ff3061;
}
.copymove_currentbg {
  background: #f4f4f4;
}

/* 화면낭독기 사용자용 */
#hd_login_msg {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.msg_sound_only,
.sound_only {
  display: inline-block !important;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 0;
  line-height: 0;
  border: 0 !important;
  overflow: hidden !important;
}
/* 본문 바로가기 */
#skip_to_container a {
  z-index: 100000;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
#skip_to_container a:focus,
#skip_to_container a:active {
  width: 100%;
  height: 75px;
  background: #21272e;
  color: #fff;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  line-height: 3.3em;
}

/* ie6 이미지 너비 지정 */
.img_fix {
  width: 100%;
  height: auto;
}

/* 캡챠 자동등록(입력)방지 기본 -pc */
#captcha {
  display: inline-block;
  position: relative;
}
#captcha legend {
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
  overflow: hidden;
}
#captcha #captcha_img {
  width: 160px;
  height: 60px;
  border: 1px solid #e9e9e9;
  vertical-align: top;
  padding: 0;
  margin: 0;
}
#captcha #captcha_mp3 {
  position: absolute;
  top: 0;
  left: 161px;
  margin: 0;
  padding: 0;
  width: 31px;
  height: 31px;
  border: 0;
  background: transparent;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
}
#captcha #captcha_mp3 span {
  position: absolute;
  top: 0;
  left: 0;
  width: 31px;
  height: 31px;
  /* background: url("../img/captcha.png") no-repeat; */
}
#captcha #captcha_reload {
  position: absolute;
  top: 31px;
  left: 161px;
  margin: 0;
  padding: 0;
  width: 31px;
  height: 31px;
  border: 0;
  background: transparent;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  /* background: url("../img/captcha.png") no-repeat 0 -30px; */
  border-top: 1px solid #ccc;
  text-indent: -999px;
}
#captcha #captcha_key {
  margin: 0 0 0 33px;
  padding: 0 5px;
  width: 70px;
  height: 60px;
  border: 1px solid #b8c9c2;
  background: #f7f7f7;
  font-size: 1.333em;
  font-weight: bold;
  text-align: center;
  line-height: 2.8em;
  vertical-align: top;
}
#captcha #captcha_info {
  display: block;
  margin: 5px 0 0;
  font-size: 0.95em;
  letter-spacing: -0.1em;
}

/* 캡챠 자동등록(입력)방지 기본 - mobile */
#captcha.m_captcha audio {
  display: block;
  margin: 0 0 5px;
  width: 187px;
}
#captcha.m_captcha #captcha_img {
  width: 160px;
  height: 60px;
  border: 1px solid #e9e9e9;
  margin-bottom: 3px;
  margin-top: 5px;
  display: block;
}
#captcha.m_captcha #captcha_reload {
  position: static;
  margin: 0;
  padding: 0;
  width: 31px;
  height: 31px;
  border: 0;
  background: transparent;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  /* background: url("../../../img/captcha.png") no-repeat 0 -30px; */
  text-indent: -999px;
}
#captcha.m_captcha #captcha_reload span {
  display: none;
}
#captcha.m_captcha #captcha_key {
  margin: 0;
  padding: 0 5px;
  width: 115px;
  height: 29px;
  border: 1px solid #b8c9c2;
  background: #f7f7f7;
  font-size: 1.333em;
  font-weight: bold;
  text-align: center;
  line-height: 29px;
  margin-left: 3px;
}
#captcha.m_captcha #captcha_info {
  display: block;
  margin: 5px 0 0;
  font-size: 0.95em;
  letter-spacing: -0.1em;
}
#captcha.m_captcha #captcha_mp3 {
  width: 31px;
  height: 31px;
  /* background: url("../img/captcha.png") no-repeat; */
  vertical-align: top;
  overflow: hidden;
  cursor: pointer;
  text-indent: -9999px;
  border: none;
}

/* ckeditor 단축키 */
.cke_sc {
  margin: 0 0 5px;
  text-align: right;
}
.btn_cke_sc {
  display: inline-block;
  padding: 0 10px;
  height: 23px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  text-decoration: none;
  line-height: 1.9em;
  vertical-align: middle;
  cursor: pointer;
}
.cke_sc_def {
  margin: 0 0 5px;
  padding: 10px;
  border: 1px solid #ccc;
  background: #f7f7f7;
  text-align: center;
}
.cke_sc_def dl {
  margin: 0 0 5px;
  text-align: left;
  zoom: 1;
}
.cke_sc_def dl:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}
.cke_sc_def dt,
.cke_sc_def dd {
  float: left;
  margin: 0;
  padding: 5px 0;
  border-bottom: 1px solid #e9e9e9;
}
.cke_sc_def dt {
  width: 20%;
  font-weight: bold;
}
.cke_sc_def dd {
  width: 30%;
}

/* 버튼 */
a.btn01 {
  display: inline-block;
  padding: 7px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  text-decoration: none;
  vertical-align: middle;
}
a.btn01:focus,
a.btn01:hover {
  text-decoration: none;
}
button.btn01 {
  display: inline-block;
  margin: 0;
  padding: 7px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  text-decoration: none;
}
a.btn02 {
  display: inline-block;
  padding: 7px;
  border: 1px solid #3b3c3f;
  background: #4b545e;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
}
a.btn02:focus,
.btn02:hover {
  text-decoration: none;
}
button.btn02 {
  display: inline-block;
  margin: 0;
  padding: 7px;
  border: 1px solid #3b3c3f;
  background: #4b545e;
  color: #fff;
  text-decoration: none;
}

.btn_confirm {
  text-align: center;
} /* 서식단계 진행 */

.btn_submit {
  padding: 8px;
  border: 0;
  background: #332323;
  color: #fff;
  letter-spacing: -0.1em;
  cursor: pointer;
}
fieldset .btn_submit {
  padding: 0 7px;
  height: 24px;
  line-height: 1em;
}

a.btn_cancel {
  display: inline-block;
  padding: 5px;
  border: 0px solid #ccc;
  background: #fafafa;
  color: #000;
  text-decoration: none;
  vertical-align: middle;
}
button.btn_cancel {
  display: inline-block;
  padding: 7px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  vertical-align: top;
  text-decoration: none;
}

a.btn_frmline,
button.btn_frmline {
  display: inline-block;
  padding: 0 5px;
  height: 24px;
  border: 0;
  background: #333;
  color: #fff;
  letter-spacing: -0.1em;
  text-decoration: none;
  vertical-align: top;
} /* 우편번호검색버튼 등 */
a.btn_frmline {
  line-height: 24px;
}
button.btn_frmline {
  font-size: 1em;
}

/* 게시판용 버튼 */
a.btn_b01 {
  display: inline-block;
  padding: 7px;
  border: 1px solid #d9ded9;
  background: #f2f5f9;
  color: #000;
  text-decoration: none;
  vertical-align: middle;
}
a.btn_b01:focus,
.btn_b01:hover {
  text-decoration: none;
}
a.btn_b02 {
  display: inline-block;
  padding: 7px 7px;
  border: 1px solid #3b3c3f;
  background: #4b545e;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
}
a.btn_b02:focus,
.btn_b02:hover {
  text-decoration: none;
}
a.btn_admin {
  display: inline-block;
  padding: 7px;
  border: 1px solid #4b545e;
  background: #4b545e;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
} /* 관리자 전용 버튼 */
a.btn_admin:focus,
a.btn_admin:hover {
  text-decoration: none;
}

/* 댓글 스타일 */
.cnt_cmt {
  display: inline-block;
  margin: 0 0 0 3px;
  font-weight: bold;
}

/* 기본테이블 */
.tbl_wrap table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.tbl_wrap caption {
  padding: 10px 0;
  font-weight: bold;
  text-align: left;
}

.tbl_head01 {
  margin: 0 0 10px;
}
.tbl_head01 caption {
  padding: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.tbl_head01 thead th {
  padding: 12px 0;
  border-top: 2px solid #9f9fa1;
  border-bottom: 1px solid #dddddf;
  background: #f5f5f5;
  color: #383838;
  font-size: 1.1em;
  text-align: center;
  letter-spacing: -0.1em;
}
.tbl_head01 thead a {
  color: #383838;
}
.tbl_head01 thead th input {
  vertical-align: top;
} /* middle 로 하면 게시판 읽기에서 목록 사용시 체크박스 라인 깨짐 */
.tbl_head01 tfoot th,
.tbl_head01 tfoot td {
  padding: 10px 0;
  border-top: 1px solid #c1d1d5;
  border-bottom: 1px solid #c1d1d5;
  background: #d7e0e2;
  text-align: center;
}
.tbl_head01 tbody th {
  padding: 8px 0;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.tbl_head01 td {
  padding: 8px 5px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  line-height: 1.5em;
  word-break: break-all;
}
.tbl_head01 a {
}

.tbl_head02 {
  margin: 0 0 10px;
}
.tbl_head02 caption {
  padding: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.tbl_head02 thead th {
  padding: 5px 0;
  border-top: 1px solid #d1dee2;
  border-bottom: 1px solid #d1dee2;
  background: #e5ecef;
  color: #383838;
  font-size: 0.95em;
  text-align: center;
  letter-spacing: -0.1em;
}
.tbl_head02 thead a {
  color: #383838;
}
.tbl_head02 thead th input {
  vertical-align: top;
} /* middle 로 하면 게시판 읽기에서 목록 사용시 체크박스 라인 깨짐 */
.tbl_head02 tfoot th,
.tbl_head02 tfoot td {
  padding: 10px 0;
  border-top: 1px solid #c1d1d5;
  border-bottom: 1px solid #c1d1d5;
  background: #d7e0e2;
  text-align: center;
}
.tbl_head02 tbody th {
  padding: 5px 0;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.tbl_head02 td {
  padding: 5px 3px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  line-height: 1.4em;
  word-break: break-all;
}
.tbl_head02 a {
}

/* 폼 테이블 */
.tbl_frm01 {
  margin: 0 0 20px;
}
.tbl_frm01 table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.tbl_frm01 th {
  width: 70px;
  padding: 7px 13px;
  border: 1px solid #e9e9e9;
  border-left: 0;
  background: #f5f8f9;
  text-align: left;
}
.tbl_frm01 td {
  padding: 7px 10px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  background: transparent;
}
.tbl_frm01 textarea,
.frm_input {
  border: 1px solid #e4eaec;
  background: #f7f7f7;
  color: #000;
  vertical-align: middle;
  line-height: 2em;
}
.tbl_frm01 textarea {
  padding: 2px 2px 3px;
}
.frm_input {
  height: 22px;
}
.tbl_frm01 textarea {
  width: 98%;
  height: 100px;
}
.tbl_frm01 a {
  text-decoration: none;
}
.tbl_frm01 .frm_file {
  display: block;
  margin-bottom: 5px;
}
.tbl_frm01 .frm_info {
  display: block;
  padding: 0 0 5px;
  line-height: 1.4em;
}

/* 자료 없는 목록 */
.empty_table {
  padding: 50px 0 !important;
  text-align: center;
}
.empty_list {
  padding: 20px 0 !important;
  text-align: center;
}

/* 필수입력 */
.required,
textarea.required {
  background: url("../img/wrest.gif") #f7f7f7 top right no-repeat !important;
}

/* 테이블 항목별 정의 */
.td_board {
  width: 120px;
  text-align: center;
}
.td_category {
  width: 80px;
  text-align: center;
}
.td_chk {
  width: 30px;
  text-align: center;
}
.td_date {
  width: 60px;
  text-align: center;
}
.td_datetime {
  width: 110px;
  text-align: center;
}
.td_group {
  width: 100px;
  text-align: center;
}
.td_mb_id {
  width: 100px;
  text-align: center;
}
.td_mng {
  width: 80px;
  text-align: center;
}
.td_name {
  width: 100px;
  text-align: left;
}
.td_nick {
  width: 100px;
  text-align: center;
}
.td_num {
  width: 50px;
  text-align: center;
}
.td_numbig {
  width: 80px;
  text-align: center;
}
.td_stat {
  width: 60px;
  text-align: center;
}

.txt_active {
  color: #5d910b;
}
.txt_done {
  color: #e8180c;
}
.txt_expired {
  color: #ccc;
}
.txt_rdy {
  color: #8abc2a;
}

/* 새창 기본 스타일 */
.new_win {
}
.new_win .tbl_wrap {
  margin: 0 20px;
}
.new_win #win_title {
  margin: 0 0 20px;
  padding: 20px;
  border-top: 3px solid #333;
  border-bottom: 1px solid #dde4e9;
  background: #fff;
  font-size: 1.2em;
}
.new_win #win_title .sv {
  font-size: 0.75em;
  line-height: 1.2em;
}
.new_win .win_ul {
  margin: -20px 0 20px 0;
  padding: 0 20px;
  border-bottom: 1px solid #455255;
  background: #484848;
  list-style: none;
  zoom: 1;
}
.new_win .win_ul:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}
.new_win .win_ul li {
  float: left;
  margin-left: -1px;
}
.new_win .win_ul a {
  display: block;
  padding: 10px 10px 8px;
  border-right: 1px solid #455255;
  border-left: 1px solid #455255;
  color: #fff;
  font-family: dotum;
  font-weight: bold;
  text-decoration: none;
}
.new_win .win_desc {
  padding: 20px;
}

.new_win .win_btn {
  clear: both;
  padding: 20px;
  text-align: center;
} /* 새창용 */
.new_win .win_btn button {
  display: inline-block;
  padding: 0 10px;
  height: 30px;
  border: 0;
  background: #4b545e;
  color: #fff;
  line-height: 2em;
  cursor: pointer;
}
.new_win .win_btn input {
  padding: 0 10px;
  height: 30px;
  line-height: 2em;
}
.new_win .win_btn a {
  display: inline-block;
  padding: 0 10px;
  height: 30px;
  background: #4b545e;
  color: #fff;
  vertical-align: middle;
  line-height: 2.4em;
}
.new_win .win_btn a:focus,
.new_win .win_btn a:hover {
  text-decoration: none;
}

/* 검색결과 색상 */
.sch_word {
  color: #ff3061;
}

/* 자바스크립트 alert 대안 */
#validation_check {
  margin: 100px auto;
  width: 500px;
}
#validation_check h1 {
  margin-bottom: 20px;
  font-size: 1.3em;
}
#validation_check p {
  margin-bottom: 20px;
  padding: 30px 20px;
  border: 1px solid #e9e9e9;
  background: #fff;
}

/* 사이드뷰 */
.sv_wrap {
  display: inline-block;
  position: relative;
  font-weight: normal;
}
.sv_wrap .sv {
  z-index: 1000;
  display: none;
  margin: 5px 0 0;
  border: 1px solid #283646;
}
.sv_wrap .sv a {
  display: inline-block;
  margin: 0;
  padding: 3px;
  width: 94px;
  border-bottom: 1px solid #283646;
  background: #111;
  color: #fff !important;
}
.sv_wrap a:focus,
.sv_wrap a:hover,
.sv_wrap a:active {
  text-decoration: none;
}
.sv_on {
  display: block !important;
  position: absolute;
  top: 10px;
  left: 20px;
  width: auto;
  height: auto;
}
.sv_nojs .sv {
  display: block;
}

/* 페이징 */
.pg_wrap {
  clear: both;
  margin: 0 0 20px;
  padding: 20px 0 0;
  text-align: center;
}
.pg {
}
.pg_page,
.pg_current {
  display: inline-block;
  padding: 0 8px;
  height: 25px;
  color: #000;
  letter-spacing: 0;
  line-height: 2.2em;
  vertical-align: middle;
}
.pg a:focus,
.pg a:hover {
  text-decoration: none;
}
.pg_page {
  background: #e4eaec;
  text-decoration: none;
}
.pg_start,
.pg_prev {
  /* 이전 */
}
.pg_end,
.pg_next {
  /* 다음 */
}
.pg_current {
  display: inline-block;
  margin: 0 4px 0 0;
  background: #333;
  color: #fff;
  font-weight: normal;
}

/* Mobile화면으로 */
#device_change {
  display: block;
  margin: 0.3em;
  padding: 0.5em 0;
  border: 1px solid #eee;
  border-radius: 2em;
  background: #fff;
  color: #000;
  font-size: 2em;
  text-decoration: none;
  text-align: center;
}

/*메일인증*/
.rg_em {
  margin-top: 5px;
}
.rg_em caption {
  padding: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}

/*캡챠 모바일에서 피시버젼*/
#captcha.m_captcha audio {
  display: block;
  margin: 5px 0 10px;
}
#captcha.m_captcha #captcha_key {
  margin-left: 3px;
}
#captcha.m_captcha #captcha_reload span {
  background: none;
  display: inline;
}
#captcha.m_captcha #captcha_reload {
  position: relative;
  width: auto;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0 10px;
  height: 43px;
  border: 0;
  background: #e4eaec;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
}

#quick {
  position: fixed;
  left: 50%;
  margin-left: -700px;
  top: 25%;
  z-index: 9999;
}
#quick img {
  width: 120px;
}
@media (max-width: 991px) {
  #quick {
    left: 2%;
    margin-left: 0;
    top: 2%;
  }
  #quick img {
    width: 80px;
  }
}
