/*공통*/
.inner {
  /* width: 1400px; */
  height: 100%;
  margin: 0 auto;
  padding: 1% 10px;
  position: relative;
}
.flex {
  display: flex;
  align-items: center;
}
.flex.js {
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .inner {
    width: 80%;
    padding: 3% 0;
  }
}
@media (max-width: 992px) {
  .inner {
    width: 90%;
    padding: 3% 0;
  }
  .flex {
    display: block;
  }
}

/* 상단 레이아웃 */
#hd {
  z-index: 4;
  position: fixed;
  width: 100%;
  min-width: 1500px;
}
#hd_h1 {
  position: absolute;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
#hd_wrapper {
  position: relative;
  margin: 0 auto;
  padding: 20px 10px;
  width: 1500px;
  zoom: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#hd_wrapper #logo {
}
#hd_wrapper .btn {
  display: block;
  font-size: 1.2em;
  font-weight: 800;
  border-radius: 40px;
  padding: 8px 20px;
  color: #fff;
  border: 4px solid #fff;
  background: #00b8b3;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1200px) {
  #hd {
    min-width: 100%;
    overflow: hidden;
  }
  #hd_wrapper {
    width: 100%;
    padding: 20px 10%;
  }
  #hd_wrapper #logo img {
    height: 30px;
  }
  #hd_wrapper .btn {
    font-size: 1em;
    padding: 5px 20px;
    border-width: 3px;
  }
}
@media (max-width: 992px) {
  #hd {
    min-width: 100%;
    overflow: hidden;
  }
  #hd_wrapper {
    width: 100%;
    padding: 20px 5%;
  }
}

.fp-arrow {
  /* display: none; */
  top: 50%;
  width: 34px;
  height: 38px;
  /* border-width: 0 !important; */
}
.fp-arrow:after {
  display: block;
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
  color: #eee;
  font-size: 4em;
  background-color: black;
}
.fp-arrow.fp-prev:after {
  /* content: "\f053"; */
}
.fp-arrow.fp-next:after {
  /* content: "\f054"; */
}

#section0 {
  background: url("../img/main/main_bg.jpg") no-repeat top center;
}
#section1 {
  background: url("../img/main/main_bg.jpg") no-repeat center -940px;
}
@media (max-width: 1200px) {
  #section0 {
    background-size: 100% 200%;
  }
}
@media (max-width: 992px) {
  #section0 {
    background-size: 150% 200%;
    background-position: 25% top;
  }
}

#section0 .flex {
  flex-direction: row-reverse;
}
#section0 .text {
  text-align: left;
  font-size: 2em;
}
#section0 .text .logo {
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
}
#section0 .text span {
  display: block;
  margin-bottom: 20px;
  opacity: 0.8;
}
#section0 .text span strong {
  display: inline-block;
  text-decoration: none;
  opacity: 1;
  border-bottom: 15px solid #ccf0f2;
  line-height: 0px;
}

#section0 .text p {
  font-size: 1.4em;
}
#section0 .text p strong {
  color: #00b4c0;
}

#section0 .img {
  display: block;
  overflow: hidden;
}

#section0 #actions {
  margin: 0 auto;
  width: 120px;
}
#section0 #actions a {
  display: none;
}
#section0 #actions #moveSectionDown {
  background: #00b4c0;
  display: block;
  line-height: 1em;
  padding: 45px 0;
}
#section0 #actions #moveSectionDown p {
}
#section0 #actions #moveSectionDown i {
  font-size: 1.5em;
  margin-top: 10px;
}

@media (max-width: 1200px) {
  #section0 .text {
    font-size: 1.5em;
    width: 60%;
  }
  #section0 .text .logo img {
    height: 50px;
  }
  #section0 .img {
    width: 40%;
    text-align: center;
    padding-top: 10%;
  }
  #section0 .img img {
    width: 80%;
  }
}
@media (max-width: 992px) {
  #section0 .text {
    font-size: 1.5em;
    text-align: center;
    width: 100%;
  }
  #section0 .text .logo img {
    height: 50px;
  }

  #section0 .img {
    width: 100%;
    padding-top: 0;
  }
  #section0 .img img {
    width: auto;
    height: 30vh;
  }
}
@media (max-width: 400px) {
  #section0 .text {
    font-size: 1em;
    text-align: center;
    width: 100%;
  }
  #section0 .text .logo img {
    height: 50px;
  }

  #section0 .img {
    width: 100%;
  }
  #section0 .img img {
    width: auto;
    height: 30vh;
  }
}

#section1 .slide {
  margin: 0 auto;
  text-align: center;
}
#section1 .img {
  width: 100%;
  height: 400px;
  overflow: hidden;
}
#section1 .text {
  background: #fff;
  position: relative;
  width: 900px;
  margin: 0 auto;
  padding: 30px;
  font-size: 1.5em;
}
#section1 .text:before {
  content: "";
  display: block;
  width: 900px;
  height: 35px;
  background: url("../img/main/shadow.png") no-repeat top center;
  position: absolute;
  top: -35px;
  left: 0;
}
#section1 .text p {
  font-size: 1.5em;
}
#section1 .text span {
  display: block;
  opacity: 0.8;
}
.circle_btn {
  display: inline-block;
  text-align: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 0;
  margin-top: 20px;
  line-height: 120px;
  color: #fff !important;
  font-size: 0.8em;
  background: #2fb4cb;
  font-weight: 600;
}
@media (max-width: 400px) {
  .circle_btn {
    display: inline-block;
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 0;
    margin-top: 20px;
    line-height: 100px;
    color: #fff !important;
    font-size: 0.8em;
    background: #2fb4cb;
    font-weight: 600;
  }
}

@media (max-width: 1200px) {
  #section1 .img {
    height: 40vh;
  }
  #section1 .img img {
    width: 70%;
  }
  #section1 .text {
    width: 100%;
  }
  #section1 .text:before {
    width: 100%;
    background-size: 100%;
    top: -20px;
  }
}
@media (max-width: 992px) {
  #section1 .img {
    height: 25vh;
  }
  #section1 .text:before {
    width: 100%;
    background-size: 100%;
    top: -10px;
  }
}

#section2 {
  background: #f5f5f5;
}
#section2 .text {
  text-align: center;
  font-size: 1.2em;
}
#section2 .text p {
  font-size: 1.3em;
}

#section2 .map_wrap {
  text-align: center;
  width: 900px;
  margin: 20px auto;
}
#section2 .root_daum_roughmap .wrap_map {
  border: 10px solid #b9e1e8;
}

@media (max-width: 992px) {
  #section2 .map_wrap {
    width: 100%;
  }
}

#footer {
  width: 100%;
  background: #e6e6e6;
  clear: both;
  padding: 20px 0;
}
#footer .logo {
  margin-right: 20px;
  filter: contrast(0%);
}
#footer .logo img {
  height: 50px;
}

address h1 {
  font-size: 1.1em;
  padding: 10px 0;
  font-weight: bold;
}
address p {
  overflow: hidden;
  font-size: 0.9em;
  line-height: 1.5em;
  color: #8f8f8f;
  font-weight: 300;
}
address p span {
  display: inline-block;
  margin-right: 10px;
}
address p.co {
  font-family: Tahoma, Geneva, sans-serif;
  font-size: 0.8em;
  letter-spacing: 0px;
  color: #686868;
}
address p.co strong {
  color: #32b5a1;
}

.fp-warning,
.fp-watermark {
  display: none;
}
