/* 폰트어썸 아이콘 */
i{margin:0 2px;}
.fa-phone{transform:rotate(100deg);}

/*브라우저 상하단이동버튼*/
#gobtn{position:fixed; display:block; right:10px; bottom:20px; display:none; z-index:9999;}
.goHd, .goFt{width:30px; height:30px; background:#F7F7F7; z-index:9999; text-indent:-9999px; border:1px solid #CCC; position:relative;
	  transition:all 0.5s; box-shadow:0px 0px 3px RGBA(0, 0, 0, 0.5); border-radius:4px; display:block; margin-bottom:3px;}
.goHd span{position:absolute; top:60%; left:9px; display:block; width:10px; height:10px; border-left:2px solid #666; border-top:2px solid #666; border-radius:2px;
    transform:translateY(-50%) rotate(45deg);}
.goFt span{position:absolute; top:40%; left:9px; display:block; width:10px; height:10px; border-left:2px solid #666; border-top:2px solid #666; border-radius:2px;
    transform:translateY(-50%) rotate(-135deg);}
.goHd:hover, .goFt:hover{background:#333; border:1px solid #333}
.goHd:hover span, .goFt:hover span{border-left:2px solid #FFF; border-top:2px solid #FFF;}

/* 준비중페이지 */
#ready{text-align:center; background:url(../img/common/ready.png) no-repeat 50% 0; margin:80px 0;  
        padding-top:130px; font-size:1.5em; line-height:1.3em; color:#333;}
#ready strong{font-weight:600; color:#23B1BD;}
#ready p{font-size:0.8em; color:#666;}

/* 블랙 표 
.tbl table{width:100%; margin:10px 0px 0px 0; font-size:0.95em; border-top:1px solid #E3E3E3; border-bottom:1px solid #E3E3E3; font-size:1.1em; line-height:1.8em; }
.tbl td, .tbl th{vertical-align:middle; border-bottom:1px solid #8C8C8C; padding:10px 15px; border-left:1px solid #8C8C8C; text-align:center;}
.tbl td{}
.tbl th{border-right:1px solid #8C8C8C; color:#FFF; background:#C4E6F6; font-weight:bold;}

.tbl thead th{border-bottom:1px solid #fff; background:#000;}
.tbl tbody th{background:#404040; border-bottom:1px solid #8C8C8C; font-weight:normal;}

.tbl tr th:first-child, .tbl tr td:first-child{border-left:0;}
.tbl tr:last-child th{border-right:0;}

.tbl table.td_left td{text-align:left;}*/

/* 화이트 표 */
.tbl table{width:100%; margin:10px 0px 0px 0; border-left:1px solid #CBCBCB; border-top:1px solid #CBCBCB; border-bottom:1px solid #E3E3E3; font-size:1em; line-height:1.5em; }
.tbl td, .tbl th{vertical-align:middle; border-bottom:1px solid #CBCBCB; padding:10px 5px; border-right:1px solid #CBCBCB; text-align:center;}
.tbl td{}
.tbl th{border-right:1px solid #CBCBCB; color:#333; background:#E3E3E3; font-weight:bold;}

.tbl thead th{border-bottom:1px solid #CBCBCB; background:#E0EEF8;}
.tbl tbody th{background:#FBFBFB; border-bottom:1px solid #CBCBCB; font-weight:normal;}

/*.tbl tr th:first-child, 
.tbl tr td:first-child{border-left:0;}
.tbl tr td:last-child,
.tbl tr th:last-child{border-right:0;}*/

.tbl table.td_left td{text-align:left;}



/*가로스크롤*/
.row-horizon {
  position:relative;
  width:98%;
  padding:0 0 20px 0;
  margin:0 auto 20px auto;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media screen and (max-width:787px) {
.row-horizon:before{content:"← 좌우스크롤로 확인해주세요 →"; display:block; text-align:center; margin-bottom:10px;}
.row-horizon {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
}


/*hoverBOX*/
.block-texts{position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity:0.6; z-index:2;}
.block-texts .drawborder{display:block; background:#FFD040; position: absolute;}

.block-texts .drawborder-top{width: 0%; height: 15px; left: 0; top: 0; transition-delay: 0s;}
.block-texts .drawborder-left{width: 15px; height: 0%; top: 0; right: 0; transition-delay: 0s;}
.block-texts .drawborder-bottom{width: 0%; height: 15px; right: 0; bottom: 0; transition-delay: 0s;}
.block-texts .drawborder-right{width: 15px; height: 0; left: 0; bottom: 0; transition-delay: 0s;}

.block-texts:hover .drawborder{transition: all 0.25s;}
.block-texts:hover .drawborder-top{width: 100%; transition-delay: 0s;}
.block-texts:hover .drawborder-left{height: 100%; transition-delay: 0.1s;}
.block-texts:hover .drawborder-bottom{width: 100%; transition-delay: 0.2s;}
.block-texts:hover .drawborder-right{height: 100%; transition-delay: 0.3s;}

/*clearfix*/
.cf{}/* 여기서 cf는 float속성을 가진 태그의 부모태그라고 생각한다.*/
.cf:after{display:block; content:""; clear: both;}
.hide{display:none;}
.cb{clear:both!important;}
.bgn{background:none!important;}
.bdn{border:none!important;}
.pa{position:absolute!important;}
.fwb{font-weight:bold!important;}
.fl{float:left;}
.fr{float:right;}

/* zero */
.r0{right:0!important;}
.l0{left:0!important;}
.b0{bottom:0!important;}
.t0{top:0!important;}

/* padding */
.pt1 { padding-top:1px!important; }
.pt2 { padding-top:2px!important; }
.pt3 { padding-top:3px!important; }
.pt4 { padding-top:4px!important; }
.pt5 { padding-top:5px!important; }
.pt6 { padding-top:6px!important; }
.pt7 { padding-top:7px!important; }
.pt8 { padding-top:8px!important; }
.pt9 { padding-top:9px!important; }
.pt10 { padding-top:10px!important; }
.pt11 { padding-top:11px!important; }
.pt12 { padding-top:12px!important; }
.pt13 { padding-top:13px!important; }
.pt14 { padding-top:14px!important; }
.pt15 { padding-top:15px!important; }
.pt16 { padding-top:16px!important; }
.pt17 { padding-top:17px!important; }
.pt18 { padding-top:18px!important; }
.pt19 { padding-top:19px!important; }
.pt20 { padding-top:20px!important; }
.pt22 { padding-top:20px!important; }
.pt24 { padding-top:24px!important; }
.pt25 { padding-top:25px!important; }
.pt30 { padding-top:30px!important; }
.pt32 { padding-top:32px!important; }
.pt34 { padding-top:34px!important; }
.pt35 { padding-top:35px!important; }
.pt38 { padding-top:38px!important; }
.pt39 { padding-top:39px!important; }
.pt40 { padding-top:40px!important; }
.pt43 { padding-top:43px!important; }
.pt50 { padding-top:50px!important; }
.pt70 { padding-top:70px!important; }
.pt100 { padding-top:100px!important; }

.pl0 { padding-left:0px!important; }
.pl1 { padding-left:1px!important; }
.pl2 { padding-left:2px!important; }
.pl3 { padding-left:3px!important; }
.pl4 { padding-left:4px!important; }
.pl5 { padding-left:5px!important; }
.pl6 { padding-left:6px!important; }
.pl7 { padding-left:7px!important; }
.pl8 { padding-left:8px!important; }
.pl9 { padding-left:9px!important; }
.pl10 { padding-left:10px!important; }
.pl11 { padding-left:11px!important; }
.pl12 { padding-left:12px!important; }
.pl13 { padding-left:13px!important; }
.pl14 { padding-left:14px!important; }
.pl15 { padding-left:15px!important; }
.pl16 { padding-left:16px!important; }
.pl17 { padding-left:17px!important; }
.pl18 { padding-left:18px!important; }
.pl19 { padding-left:19px!important; }
.pl20 { padding-left:20px!important; }
.pl21 { padding-left:21px!important; }
.pl22 { padding-left:22px!important; }
.pl28 { padding-left:22px!important; }
.pl30 { padding-left:30px!important; }
.pl31 { padding-left:31px!important; }
.pl40 { padding-left:40px!important; }
.pl48 { padding-left:48px!important; }
.pl50 { padding-left:50px!important; }
.pl60 { padding-left:60px!important; }
.pl70 { padding-left:70px!important; }
.pl75 { padding-left:75px!important; }
.pl140 { padding-left:140px!important; }
.pl200 { padding-left:200px!important; }


.pr1 { padding-right:1px!important; }
.pr2 { padding-right:2px!important; }
.pr3 { padding-right:3px!important; }
.pr4 { padding-right:4px!important; }
.pr5 { padding-right:5px!important; }
.pr6 { padding-right:6px!important; }
.pr7 { padding-right:7px!important; }
.pr8 { padding-right:8px!important; }
.pr9 { padding-right:9px!important; }
.pr10 { padding-right:10px!important; }
.pr11 { padding-right:11px!important; }
.pr12 { padding-right:12px!important; }
.pr13 { padding-right:13px!important; }
.pr14 { padding-right:14px!important; }
.pr15 { padding-right:15px!important; }
.pr16 { padding-right:16px!important; }
.pr17 { padding-right:17px!important; }
.pr18 { padding-right:18px!important; }
.pr19 { padding-right:19px!important; }
.pr20 { padding-right:20px!important; }
.pr21 { padding-right:21px!important; }
.pr25 { padding-right:25px!important; }
.pr28 { padding-right:28px!important; }
.pr30 { padding-right:30px!important; }
.pr40 { padding-right:40px!important; }
.pr50 { padding-right:50px!important; }


.pb1 { padding-bottom:1px!important; }
.pb2 { padding-bottom:2px!important; }
.pb3 { padding-bottom:3px!important; }
.pb4 { padding-bottom:4px!important; }
.pb5 { padding-bottom:5px!important; }
.pb6 { padding-bottom:6px!important; }
.pb7 { padding-bottom:7px!important; }
.pb8 { padding-bottom:8px!important; }
.pb9 { padding-bottom:9px!important; }
.pb10 { padding-bottom:10px!important; }
.pb11 { padding-bottom:11px!important; }
.pb12 { padding-bottom:12px!important; }
.pb13 { padding-bottom:13px!important; }
.pb14 { padding-bottom:14px!important; }
.pb15 { padding-bottom:15px!important; }
.pb16 { padding-bottom:16px!important; }
.pb17 { padding-bottom:17px!important; }
.pb18 { padding-bottom:18px!important; }
.pb19 { padding-bottom:19px!important; }
.pb20 { padding-bottom:20px!important; }
.pb30 { padding-bottom:30px!important; }
.pb40 { padding-bottom:40px!important; }
.pb50 { padding-bottom:50px!important; }
.pb70 { padding-bottom:70px!important; }
.pb80 { padding-bottom:80px!important; }
.pb100 { padding-bottom:100px!important; }
.pb120 { padding-bottom:120px!important; }

/* margin */
.mt0 { margin-top:0px!important; }
.mt1 { margin-top:1px!important; }
.mt2 { margin-top:2px!important; }
.mt3 { margin-top:3px!important; }
.mt4 { margin-top:4px!important; }
.mt5 { margin-top:5px!important; }
.mt6 { margin-top:6px!important; }
.mt7 { margin-top:7px!important; }
.mt8 { margin-top:8px!important; }
.mt9 { margin-top:9px!important; }
.mt10 { margin-top:10px!important; }
.mt11 { margin-top:11px!important; }
.mt12 { margin-top:12px!important; }
.mt13 { margin-top:13px!important; }
.mt14 { margin-top:14px!important; }
.mt15 { margin-top:15px!important; }
.mt16 { margin-top:16px!important; }
.mt20 { margin-top:20px!important; }
.mt23 { margin-top:23px!important; }
.mt25 { margin-top:25px!important; }
.mt26 { margin-top:26px!important; }
.mt28 { margin-top:28px!important; }
.mt30 { margin-top:30px!important; }
.mt40 { margin-top:40px!important; }
.mt43 { margin-top:43px!important; }
.mt47 { margin-top:47px!important; }
.mt100 { margin-top:100px!important; }

.mt_5 { margin-top:-5px!important; }
.mt_6 { margin-top:-6px!important; }
.mt_7 { margin-top:-7px!important; }
.mt_8 { margin-top:-8px!important; }
.mt_9 { margin-top:-9px!important; }
.mt_10 { margin-top:-10px!important; }

.ml0 { margin-left:0px!important; }
.ml1 { margin-left:1px!important; }
.ml6 { margin-left:6px!important; }
.ml7 { margin-left:7px!important; }
.ml9 { margin-left:9px!important; }
.ml10 { margin-left:10px!important; }
.ml11 { margin-left:11px!important; }
.ml12 { margin-left:12px!important; }
.ml18 { margin-left:18px!important; }
.ml20 { margin-left:20px!important; }
.ml22 { margin-left:22px!important; }
.ml45 { margin-left:45px!important; }
.ml49 { margin-left:49px!important; }
.ml50 { margin-left:50px!important; }
.ml200 { margin-left:200px!important; }
.ml210 { margin-left:210px!important; }
.ml250 { margin-left:250px!important; }
.ml300 { margin-left:300px!important; }
.ml350 { margin-left:350px!important; }
.ml400 { margin-left:400px!important; }
.ml500 { margin-left:500px!important; }
.ml600 { margin-left:600px!important; }

.mr0 { margin-right:0px!important; }
.mr1 { margin-right:1px!important; }
.mr3 { margin-right:3px!important; }
.mr6 { margin-right:6px!important; }
.mr10 { margin-right:10px!important; }
.mr11 { margin-right:11px!important; }
.mr12 { margin-right:12px!important; }
.mr16 { margin-right:16px!important; }
.mr21 { margin-right:21px!important; }
.mr25 { margin-right:25px!important; }
.mr35 { margin-right:35px!important; }

.mb0 { margin-bottom:0px!important; }
.mb1 { margin-bottom:1px!important; }
.mb2 { margin-bottom:2px!important; }
.mb4 { margin-bottom:4px!important; }
.mb5 { margin-bottom:5px!important; }
.mb6 { margin-bottom:6px!important; }
.mb7 { margin-bottom:7px!important; }
.mb8 { margin-bottom:8px!important; }
.mb10 { margin-bottom:10px!important; }
.mb11 { margin-bottom:11px!important; }
.mb12 { margin-bottom:12px!important; }
.mb13 { margin-bottom:13px!important; }
.mb20 { margin-bottom:20px!important; }
.mb30 { margin-bottom:30px!important; }
.mb35 { margin-bottom:35px!important; }
.mb40 { margin-bottom:40px!important; }
.mb50 { margin-bottom:50px!important; }
.mb60 { margin-bottom:60px!important; }
.mb80 { margin-bottom:80px!important; }


