@charset "utf-8";
/* SIR 지운아빠 */
@import url(http://fonts.googleapis.com/earlyaccess/notosanskr.css);

/* 초기화 */
html {
  overflow-y: scroll;
}
body {
  margin: 0;
  padding: 0;
  font-size: 0.75em;
  letter-spacing: -0.5px;
  font-family: "Noto Sans KR", sans-serif;
}
html,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
img {
  margin: 0;
  padding: 0;
  border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-family: "Noto Sans KR", sans-serif;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul,
li,
dd,
dl,
dt {
  list-style: none;
}

#hd ul,
nav ul,
#ft ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
legend {
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
  overflow: hidden;
}
label,
input,
button,
select,
img {
  vertical-align: middle;
}
input,
button {
  margin: 0;
  padding: 0;
  font-size: 1em;
}
button {
  cursor: pointer;
}

textarea,
select {
  font-size: 1em;
}
select {
  margin: 0;
}
p {
  margin: 0;
  padding: 0;
  word-break: break-all;
}
hr {
  display: none;
}
pre {
  overflow-x: scroll;
  font-size: 1.1em;
}
a:link,
a:visited {
  color: #000;
  text-decoration: none;
}
a:hover,
a:focus,
a:active {
  color: #000;
  text-decoration: none;
}

/* 팝업레이어 */
#hd_pop {
  z-index: 1000;
  position: relative;
  margin: 0 auto;
  width: 970px;
  height: 0;
}
#hd_pop h2 {
  position: absolute;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.hd_pops {
  position: absolute;
  border: 1px solid #e9e9e9;
  background: #fff;
}
.hd_pops_con {
}
.hd_pops_footer {
  padding: 10px 0;
  background: #000;
  color: #fff;
  text-align: right;
}
.hd_pops_footer button {
  margin-right: 5px;
  padding: 5px 10px;
  border: 0;
  background: #393939;
  color: #fff;
}

/* 상단 레이아웃 */
#hd {
  z-index: 4;
  position: relative;
  min-width: 1100px;
  /* background: url(../img/top_bg.gif) repeat-x top; */
}
.hd_zindex {
  z-index: 10 !important;
}
#hd_h1 {
  position: absolute;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
#hd_wrapper {
  position: relative;
  margin: 0 auto;
  width: 1100px;
  zoom: 1;
  position: relative;
}
#hd_wrapper:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}

#logo {
  float: left;
  padding: 63px 0 22px 0;
}

/* 전체 검색 */
#hd_sch {
  float: left;
  margin: 0 0 0 20px;
  padding: 0;
  border: 1px solid #c3c6ca;
}
#hd_sch legend {
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
  overflow: hidden;
}
#hd_sch #sch_stx {
  padding-left: 5px;
  width: 110px;
  height: 24px;
  border: 0;
  background: #fff;
  line-height: 1.9em !important;
  line-height: 1.6em;
}
#hd_sch #sch_submit {
  padding: 0 5px;
  height: 26px;
  border: 0;
  background: #e2e6eb;
  color: #333;
  cursor: pointer;
}

/* 텍스트 크기 조절 */
#text_size {
  float: left;
  margin: 0 0 0 10px;
  letter-spacing: -3px;
}
#text_size button {
  margin: 0;
  padding: 1px 2px;
  border: 1px solid #c3c6ca;
  background: transparent;
  vertical-align: middle;
  cursor: pointer;
}
.ts_up {
  font-size: 1.167em !important;
}
.ts_up2 {
  font-size: 1.3em !important;
}

/* 상단 회원가입 등 링크 */
#tnb {
  float: right;
  margin: 0;
  padding: 0;
  list-style: none;
  zoom: 1;
}
#tnb:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}
#tnb li {
  float: left;
  margin: 0 0 0 0;
}
#tnb a {
  display: inline-block;
  padding: 0 8px;
  color: #999;
  font-size: 1em;
  line-height: 2.8em;
}
#tnb li.login a {
  border: 1px solid #bfbfbf;
  padding: 3px 7px;
  border-radius: 5px;
  line-height: 2em;
  margin-top: 3px;
}
#tnb a:focus,
#tnb a:hover,
#tnb a:active {
  text-decoration: none;
}
#tnb img {
  margin-right: 3px;
}

/* 메인메뉴 */
#gnb {
  position: absolute;
  top: 75px;
  right: 0px;
}
#gnb h2 {
  position: absolute;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
#gnb_1dul {
  margin: 0 auto !important;
  padding: 0;
  width: 850px;
  zoom: 1;
}
#gnb_1dul:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}
.gnb_1dli {
  z-index: 10;
  position: relative;
  float: left;
}
.gnb_1da {
  display: inline-block;
  padding: 0 30px 20px 0px;
  height: 35px;
  font-size: 1.5em;
  line-height: 2.95em;
  text-decoration: none;
}
.gnb_1da:focus,
.gnb_1da:hover {
  text-decoration: none;
  color: #835e17;
}
.gnb_1dli {
  position: relative;
}
.gnb_1dli_air .gnb_1da {
  color: #424242;
}
.gnb_1dli_on .gnb_1da {
  color: #835e17;
}
.gnb_2dul {
  display: none;
  position: absolute;
  top: 45px;
  left: 100px;
  width: 143px;
  border: 1px solid #ab791d;
}
.gnb_2da {
  display: block;
  padding: 11px 10px;
  text-align: left;
  text-decoration: none;
  font-size: 1.08em;
  border-bottom: 1px solid #ddd;
}
.gnb_1dli_air .gnb_2da {
  background: #fff;
  color: #6f6f6f;
}
.gnb_1dli_on .gnb_2da {
  background: #fff;
  color: #6f6f6f;
}
.gnb_2da:focus,
.gnb_2da:hover {
  background: #c48a21;
  text-decoration: none;
  color: #fff;
}
.gnb_1dli_over .gnb_2dul {
  display: block;
  left: 0;
  background: #fff;
}
.gnb_1dli_over2 .gnb_2dul {
  display: block;
  right: 0;
  background: #fff;
}

#gnb_empty {
  padding: 10px 0;
  width: 100%;
  text-align: center;
  line-height: 2em;
}
#gnb_empty a {
  text-decoration: underline;
}

/*메인비쥬얼*/
#mvisual_wrap {
  width: 100%;
  height: 443px;
  position: relative;
}
/*메인롤링*/
#m_slogan {
  position: absolute;
  z-index: 1;
  top: 65px;
  left: 45%;
  margin-left: 100px;
  height: 0px;
}
#mvisual {
  position: absolute;
  top: 40px;
  left: 50%;
  margin-left: -550px;
  width: 450px;
  height: 459px;
  z-index: 1;
}
#mvisual #slider {
  width: 450px;
  height: 370px;
}
#mvisual #slider .s1,
#slider .s2 {
  width: 450px;
  height: 370px;
}
/* 슬라이더이미지를 담는 틀 */
.slidesjs-container,
.slidesjs-control {
  width: 100%;
  height: 443px !important;
}
/* 플레이,스탑,이전,다음버튼에 공통으로 작성된 클래스이름 */
a.slidesjs-navigation {
  display: block;
  width: 18px;
  height: 35px;
  text-indent: -9999px;
  position: absolute;
  top: 437px;
  left: 50%;
  margin-left: -500px;
  z-index: 999;
}
a.slidesjs-play {
  /* background: url(../images/pager_play.gif) no-repeat; */
  margin-left: -566px;
}
a.slidesjs-stop {
  /* background: url(../images/pager_stop.gif) no-repeat; */
  margin-left: -566px;
}
a.slidesjs-previous {
  /* background: url(../images_new/pager_left.png) no-repeat; */
  margin-left: -80px;
}
a.slidesjs-next {
  /* background: url(../images_new/pager_right.png) no-repeat; */
  margin-left: 55px;
}
/*페이저버튼 ul태그*/
.slidesjs-pagination {
  width: 70px;
  position: absolute;
  top: 300px;
  left: 50%;
  margin-left: 605px !important;
  z-index: 9999;
}
.slidesjs-pagination:after {
  display: block;
  content: "";
  clear: both;
}
.slidesjs-pagination li {
  float: left;
  margin: 0 3px;
}
.slidesjs-pagination li a {
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 15px;
  background: #fff;
  text-indent: -9999px;
}
.slidesjs-pagination li a.active {
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 15px;
  background: #a6761d;
  text-indent: -9999px;
}

/*메인컨텐츠*/
#mcont {
  width: 1100px;
  margin: 40px auto;
}
#mcont:after {
  display: block;
  content: "";
  clear: both;
}
#mcont p {
  float: left;
}
#mcont p.p1 {
  width: 401px;
}
#mcont p.p2 {
  width: 352px;
}
#mcont .bbs {
  display: inline-block;
  width: 303px;
  margin-left: 40px;
}

/* 중간 레이아웃 */
#wrapper {
  z-index: 5;
  margin: 0 auto;
  width: 100%;
  zoom: 1;
}
#wrapper:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}

#aside {
  float: right;
  margin: 0 0 0 -1px;
  width: 210px;
  border-left: 1px solid #dde4e9;
  background: #fff;
}

#container_index {
  position: relative;
  width: 100%;
  height: auto !important;
  font-size: 1em;
  zoom: 1;
}
#container {
  width: 1100px;
  margin: 0 auto;
  height: auto !important;
  border-left: 1px solid #ddd;
  background: #fff;
  font-size: 1em;
  zoom: 1;
  position: relative;
}
#container:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}
#svisual {
  width: 100%;
  height: 170px;
}
#left {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  border-right: 1px solid #ddd;
}
#scont_wrap {
  position: relative;
  margin-left: 200px;
  padding-bottom: 50px;
  border-left: 1px solid #ddd;
  min-height: 400px;
}
#scont {
  padding: 40px 0 0 30px;
  width: 900px;
  box-sizing: border-box;
}

/*서브-내용부분*/
#sub_title {
  margin-bottom: 40px;
}
.p_info ul {
  padding: 0;
  margin: 0;
}
.p_info ul:after {
  display: block;
  content: "";
  clear: both;
}
.p_info li {
  font-size: 1me;
  color: #8d8d8d;
  float: left;
  line-height: 15px;
}
.p_info li a {
  font-size: 1me;
  color: #8d8d8d;
  float: left;
}
.p_info li.pt {
  font-size: 1em;
  color: #a98138;
  font-weight: bold;
}
.container_title {
  font-size: 3.33em;
  color: #404040;
  line-height: 45px;
  letter-spacing: -3px;
}

/* 하단 레이아웃 */
#ft {
  min-width: 1100px;
  border-top: 1px solid #dde4e9;
  background: #f2f5f9;
}
#ft_copy {
  position: relative;
  margin: 0 auto;
  padding: 30px 0 50px 0;
  width: 1100px;
  color: #878787;
  font-size: 1em;
  letter-spacing: 0;
  line-height: 18px;
}
#ft_copy a {
  display: inline-block;
  color: inherit;
  position: absolute;
  top: 0;
  right: 0;
}

/* 게시물 선택복사 선택이동 */
#copymove {
}
.copymove_current {
  float: right;
  color: #ff3061;
}
.copymove_currentbg {
  background: #f4f4f4;
}

/* 화면낭독기 사용자용 */
#hd_login_msg {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.msg_sound_only,
.sound_only {
  display: inline-block !important;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 0;
  line-height: 0;
  border: 0 !important;
  overflow: hidden !important;
}
/* 본문 바로가기 */
#skip_to_container a {
  z-index: 100000;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
#skip_to_container a:focus,
#skip_to_container a:active {
  width: 100%;
  height: 75px;
  background: #21272e;
  color: #fff;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  line-height: 3.3em;
}

/* ie6 이미지 너비 지정 */
.img_fix {
  width: 100%;
  height: auto;
}

/* 캡챠 자동등록(입력)방지 기본 -pc */
#captcha {
  display: inline-block;
  position: relative;
}
#captcha legend {
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
  overflow: hidden;
}
#captcha #captcha_img {
  width: 160px;
  height: 60px;
  border: 1px solid #e9e9e9;
  vertical-align: top;
  padding: 0;
  margin: 0;
}
#captcha #captcha_mp3 {
  position: absolute;
  top: 0;
  left: 161px;
  margin: 0;
  padding: 0;
  width: 31px;
  height: 31px;
  border: 0;
  background: transparent;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
}
#captcha #captcha_mp3 span {
  position: absolute;
  top: 0;
  left: 0;
  width: 31px;
  height: 31px;
  /* background: url("../../../img/captcha.png") no-repeat; */
}
#captcha #captcha_reload {
  position: absolute;
  top: 31px;
  left: 161px;
  margin: 0;
  padding: 0;
  width: 31px;
  height: 31px;
  border: 0;
  background: transparent;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  /* background: url("../img/captcha.png") no-repeat 0 -30px; */
  border-top: 1px solid #ccc;
  text-indent: -999px;
}
#captcha #captcha_key {
  margin: 0 0 0 33px;
  padding: 0 5px;
  width: 70px;
  height: 60px;
  border: 1px solid #b8c9c2;
  background: #f7f7f7;
  font-size: 1.333em;
  font-weight: bold;
  text-align: center;
  line-height: 2.8em;
  vertical-align: top;
}
#captcha #captcha_info {
  display: block;
  margin: 5px 0 0;
  font-size: 0.95em;
  letter-spacing: -0.1em;
}

/* 캡챠 자동등록(입력)방지 기본 - mobile */
#captcha.m_captcha audio {
  display: block;
  margin: 0 0 5px;
  width: 187px;
}
#captcha.m_captcha #captcha_img {
  width: 160px;
  height: 60px;
  border: 1px solid #e9e9e9;
  margin-bottom: 3px;
  margin-top: 5px;
  display: block;
}
#captcha.m_captcha #captcha_reload {
  position: static;
  margin: 0;
  padding: 0;
  width: 31px;
  height: 31px;
  border: 0;
  background: transparent;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  /* background: url("../../../img/captcha.png") no-repeat 0 -30px; */
  text-indent: -999px;
}
#captcha.m_captcha #captcha_reload span {
  display: none;
}
#captcha.m_captcha #captcha_key {
  margin: 0;
  padding: 0 5px;
  width: 115px;
  height: 29px;
  border: 1px solid #b8c9c2;
  background: #f7f7f7;
  font-size: 1.333em;
  font-weight: bold;
  text-align: center;
  line-height: 29px;
  margin-left: 3px;
}
#captcha.m_captcha #captcha_info {
  display: block;
  margin: 5px 0 0;
  font-size: 0.95em;
  letter-spacing: -0.1em;
}
#captcha.m_captcha #captcha_mp3 {
  width: 31px;
  height: 31px;
  /* background: url("../img/captcha.png") no-repeat; */
  vertical-align: top;
  overflow: hidden;
  cursor: pointer;
  text-indent: -9999px;
  border: none;
}

/* ckeditor 단축키 */
.cke_sc {
  margin: 0 0 5px;
  text-align: right;
}
.btn_cke_sc {
  display: inline-block;
  padding: 0 10px;
  height: 23px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  text-decoration: none;
  line-height: 1.9em;
  vertical-align: middle;
  cursor: pointer;
}
.cke_sc_def {
  margin: 0 0 5px;
  padding: 10px;
  border: 1px solid #ccc;
  background: #f7f7f7;
  text-align: center;
}
.cke_sc_def dl {
  margin: 0 0 5px;
  text-align: left;
  zoom: 1;
}
.cke_sc_def dl:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}
.cke_sc_def dt,
.cke_sc_def dd {
  float: left;
  margin: 0;
  padding: 5px 0;
  border-bottom: 1px solid #e9e9e9;
}
.cke_sc_def dt {
  width: 20%;
  font-weight: bold;
}
.cke_sc_def dd {
  width: 30%;
}

/* 버튼 */
a.btn01 {
  display: inline-block;
  padding: 7px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  text-decoration: none;
  vertical-align: middle;
}
a.btn01:focus,
a.btn01:hover {
  text-decoration: none;
}
button.btn01 {
  display: inline-block;
  margin: 0;
  padding: 7px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  text-decoration: none;
}
a.btn02 {
  display: inline-block;
  padding: 7px;
  border: 1px solid #3b3c3f;
  background: #4b545e;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
}
a.btn02:focus,
.btn02:hover {
  text-decoration: none;
}
button.btn02 {
  display: inline-block;
  margin: 0;
  padding: 7px;
  border: 1px solid #3b3c3f;
  background: #4b545e;
  color: #fff;
  text-decoration: none;
}

.btn_confirm {
  text-align: center;
} /* 서식단계 진행 */

.btn_submit {
  padding: 8px;
  border: 0;
  background: #332323;
  color: #fff;
  letter-spacing: -0.1em;
  cursor: pointer;
}
fieldset .btn_submit {
  padding: 0 7px;
  height: 24px;
  line-height: 1em;
}

a.btn_cancel {
  display: inline-block;
  padding: 5px;
  border: 0px solid #ccc;
  background: #fafafa;
  color: #000;
  text-decoration: none;
  vertical-align: middle;
}
button.btn_cancel {
  display: inline-block;
  padding: 7px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  vertical-align: top;
  text-decoration: none;
}

a.btn_frmline,
button.btn_frmline {
  display: inline-block;
  padding: 0 5px;
  height: 24px;
  border: 0;
  background: #333;
  color: #fff;
  letter-spacing: -0.1em;
  text-decoration: none;
  vertical-align: top;
} /* 우편번호검색버튼 등 */
a.btn_frmline {
  line-height: 24px;
}
button.btn_frmline {
  font-size: 1em;
}

/* 게시판용 버튼 */
a.btn_b01 {
  display: inline-block;
  padding: 7px;
  border: 1px solid #d9ded9;
  background: #f2f5f9;
  color: #000;
  text-decoration: none;
  vertical-align: middle;
}
a.btn_b01:focus,
.btn_b01:hover {
  text-decoration: none;
}
a.btn_b02 {
  display: inline-block;
  padding: 7px 7px;
  border: 1px solid #3b3c3f;
  background: #4b545e;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
}
a.btn_b02:focus,
.btn_b02:hover {
  text-decoration: none;
}
a.btn_admin {
  display: inline-block;
  padding: 7px;
  border: 1px solid #4b545e;
  background: #4b545e;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
} /* 관리자 전용 버튼 */
a.btn_admin:focus,
a.btn_admin:hover {
  text-decoration: none;
}

/* 댓글 스타일 */
.cnt_cmt {
  display: inline-block;
  margin: 0 0 0 3px;
  font-weight: bold;
}

/* 기본테이블 */
.tbl_wrap table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.tbl_wrap caption {
  padding: 10px 0;
  font-weight: bold;
  text-align: left;
}

.tbl_head01 {
  margin: 0 0 10px;
}
.tbl_head01 caption {
  padding: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.tbl_head01 thead th {
  padding: 12px 0;
  border-top: 2px solid #9f9fa1;
  border-bottom: 1px solid #dddddf;
  background: #f5f5f5;
  color: #383838;
  font-size: 1.1em;
  text-align: center;
  letter-spacing: -0.1em;
}
.tbl_head01 thead a {
  color: #383838;
}
.tbl_head01 thead th input {
  vertical-align: top;
} /* middle 로 하면 게시판 읽기에서 목록 사용시 체크박스 라인 깨짐 */
.tbl_head01 tfoot th,
.tbl_head01 tfoot td {
  padding: 10px 0;
  border-top: 1px solid #c1d1d5;
  border-bottom: 1px solid #c1d1d5;
  background: #d7e0e2;
  text-align: center;
}
.tbl_head01 tbody th {
  padding: 8px 0;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.tbl_head01 td {
  padding: 8px 5px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  line-height: 1.5em;
  word-break: break-all;
}
.tbl_head01 a {
}

.tbl_head02 {
  margin: 0 0 10px;
}
.tbl_head02 caption {
  padding: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.tbl_head02 thead th {
  padding: 5px 0;
  border-top: 1px solid #d1dee2;
  border-bottom: 1px solid #d1dee2;
  background: #e5ecef;
  color: #383838;
  font-size: 0.95em;
  text-align: center;
  letter-spacing: -0.1em;
}
.tbl_head02 thead a {
  color: #383838;
}
.tbl_head02 thead th input {
  vertical-align: top;
} /* middle 로 하면 게시판 읽기에서 목록 사용시 체크박스 라인 깨짐 */
.tbl_head02 tfoot th,
.tbl_head02 tfoot td {
  padding: 10px 0;
  border-top: 1px solid #c1d1d5;
  border-bottom: 1px solid #c1d1d5;
  background: #d7e0e2;
  text-align: center;
}
.tbl_head02 tbody th {
  padding: 5px 0;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.tbl_head02 td {
  padding: 5px 3px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  line-height: 1.4em;
  word-break: break-all;
}
.tbl_head02 a {
}

/* 폼 테이블 */
.tbl_frm01 {
  margin: 0 0 20px;
}
.tbl_frm01 table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.tbl_frm01 th {
  width: 70px;
  padding: 7px 13px;
  border: 1px solid #e9e9e9;
  border-left: 0;
  background: #f5f8f9;
  text-align: left;
}
.tbl_frm01 td {
  padding: 7px 10px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  background: transparent;
}
.tbl_frm01 textarea,
.frm_input {
  border: 1px solid #e4eaec;
  background: #f7f7f7;
  color: #000;
  vertical-align: middle;
  line-height: 2em;
}
.tbl_frm01 textarea {
  padding: 2px 2px 3px;
}
.frm_input {
  height: 22px;
}
.tbl_frm01 textarea {
  width: 98%;
  height: 100px;
}
.tbl_frm01 a {
  text-decoration: none;
}
.tbl_frm01 .frm_file {
  display: block;
  margin-bottom: 5px;
}
.tbl_frm01 .frm_info {
  display: block;
  padding: 0 0 5px;
  line-height: 1.4em;
}

/* 자료 없는 목록 */
.empty_table {
  padding: 50px 0 !important;
  text-align: center;
}
.empty_list {
  padding: 20px 0 !important;
  text-align: center;
}

/* 필수입력 */
.required,
textarea.required {
  /* background: url("../img/wrest.gif") #f7f7f7 top right no-repeat !important; */
}

/* 테이블 항목별 정의 */
.td_board {
  width: 120px;
  text-align: center;
}
.td_category {
  width: 80px;
  text-align: center;
}
.td_chk {
  width: 30px;
  text-align: center;
}
.td_date {
  width: 60px;
  text-align: center;
}
.td_datetime {
  width: 110px;
  text-align: center;
}
.td_group {
  width: 100px;
  text-align: center;
}
.td_mb_id {
  width: 100px;
  text-align: center;
}
.td_mng {
  width: 80px;
  text-align: center;
}
.td_name {
  width: 100px;
  text-align: left;
}
.td_nick {
  width: 100px;
  text-align: center;
}
.td_num {
  width: 50px;
  text-align: center;
}
.td_numbig {
  width: 80px;
  text-align: center;
}
.td_stat {
  width: 60px;
  text-align: center;
}

.txt_active {
  color: #5d910b;
}
.txt_done {
  color: #e8180c;
}
.txt_expired {
  color: #ccc;
}
.txt_rdy {
  color: #8abc2a;
}

/* 새창 기본 스타일 */
.new_win {
}
.new_win .tbl_wrap {
  margin: 0 20px;
}
.new_win #win_title {
  margin: 0 0 20px;
  padding: 20px;
  border-top: 3px solid #333;
  border-bottom: 1px solid #dde4e9;
  background: #fff;
  font-size: 1.2em;
}
.new_win #win_title .sv {
  font-size: 0.75em;
  line-height: 1.2em;
}
.new_win .win_ul {
  margin: -20px 0 20px 0;
  padding: 0 20px;
  border-bottom: 1px solid #455255;
  background: #484848;
  list-style: none;
  zoom: 1;
}
.new_win .win_ul:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}
.new_win .win_ul li {
  float: left;
  margin-left: -1px;
}
.new_win .win_ul a {
  display: block;
  padding: 10px 10px 8px;
  border-right: 1px solid #455255;
  border-left: 1px solid #455255;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}
.new_win .win_desc {
  padding: 20px;
}

.new_win .win_btn {
  clear: both;
  padding: 20px;
  text-align: center;
} /* 새창용 */
.new_win .win_btn button {
  display: inline-block;
  padding: 0 10px;
  height: 30px;
  border: 0;
  background: #4b545e;
  color: #fff;
  line-height: 2em;
  cursor: pointer;
}
.new_win .win_btn input {
  padding: 0 10px;
  height: 30px;
  line-height: 2em;
}
.new_win .win_btn a {
  display: inline-block;
  padding: 0 10px;
  height: 30px;
  background: #4b545e;
  color: #fff;
  vertical-align: middle;
  line-height: 2.4em;
}
.new_win .win_btn a:focus,
.new_win .win_btn a:hover {
  text-decoration: none;
}

/* 검색결과 색상 */
.sch_word {
  color: #ff3061;
}

/* 자바스크립트 alert 대안 */
#validation_check {
  margin: 100px auto;
  width: 500px;
}
#validation_check h1 {
  margin-bottom: 20px;
  font-size: 1.3em;
}
#validation_check p {
  margin-bottom: 20px;
  padding: 30px 20px;
  border: 1px solid #e9e9e9;
  background: #fff;
}

/* 사이드뷰 */
.sv_wrap {
  display: inline-block;
  position: relative;
  font-weight: normal;
}
.sv_wrap .sv {
  z-index: 1000;
  display: none;
  margin: 5px 0 0;
  border: 1px solid #283646;
}
.sv_wrap .sv a {
  display: inline-block;
  margin: 0;
  padding: 3px;
  width: 94px;
  border-bottom: 1px solid #283646;
  background: #111;
  color: #fff !important;
}
.sv_wrap a:focus,
.sv_wrap a:hover,
.sv_wrap a:active {
  text-decoration: none;
}
.sv_on {
  display: block !important;
  position: absolute;
  top: 10px;
  left: 20px;
  width: auto;
  height: auto;
}
.sv_nojs .sv {
  display: block;
}

/* 페이징 */
.pg_wrap {
  clear: both;
  margin: 0 0 20px;
  padding: 20px 0 0;
  text-align: center;
}
.pg {
}
.pg_page,
.pg_current {
  display: inline-block;
  padding: 0 8px;
  height: 25px;
  color: #000;
  letter-spacing: 0;
  line-height: 2.2em;
  vertical-align: middle;
}
.pg a:focus,
.pg a:hover {
  text-decoration: none;
}
.pg_page {
  background: #e4eaec;
  text-decoration: none;
}
.pg_start,
.pg_prev {
  /* 이전 */
}
.pg_end,
.pg_next {
  /* 다음 */
}
.pg_current {
  display: inline-block;
  margin: 0 4px 0 0;
  background: #333;
  color: #fff;
  font-weight: normal;
}

/* Mobile화면으로 */
#device_change {
  display: block;
  margin: 0.3em;
  padding: 0.5em 0;
  border: 1px solid #eee;
  border-radius: 2em;
  background: #fff;
  color: #000;
  font-size: 2em;
  text-decoration: none;
  text-align: center;
}

/*메일인증*/
.rg_em {
  margin-top: 5px;
}
.rg_em caption {
  padding: 0;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}

/*캡챠 모바일에서 피시버젼*/
#captcha.m_captcha audio {
  display: block;
  margin: 5px 0 10px;
}
#captcha.m_captcha #captcha_key {
  margin-left: 3px;
}
#captcha.m_captcha #captcha_reload span {
  background: none;
  display: inline;
}
#captcha.m_captcha #captcha_reload {
  position: relative;
  width: auto;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0 10px;
  height: 43px;
  border: 0;
  background: #e4eaec;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
}
